import CatalogWrapper, {
  CatalogWrapperType
} from '@/components/GamesCatalog/CatalogWrapper';
import { getStrapiContent } from '@/helpers/strapi';
import getCatalog from '@/services/getCatalog';
import {
  getFullLicenses,
  getLicenseBySegment,
  getSegmentByLicense
} from '@/utils/multiDomains';
import { GetStaticPaths, GetStaticProps } from 'next';
import { useAuth } from '@/context/Auth';
import { useWallet } from '@/context/Wallet';
import { useEffect } from 'react';
import GTM from '@/helpers/googleTagManager';
import { getSeoTagsConfig } from '@/helpers/seo';
import {
  HomeContent,
  StrapiHomeContent
} from '@/types/api/ge-strapi/home-content';
import { getFallbackLanguage } from '@/helpers/lang';

const HomeCasino = ({
  dataStore,
  games,
  license,
  locale,
  strapiContent
}: CatalogWrapperType & {
  locale: string;
  strapiContent: HomeContent;
}) => {
  const { userData, isLoginFetching } = useAuth();
  const { balance } = useWallet();
  useEffect(() => {
    const skipGTM = userData ? !balance : false;
    if (isLoginFetching || skipGTM) return;
    GTM.pageView({
      page: {
        name: 'games/list/all',
        language: locale || 'fr'
      },
      user: userData,
      wallet: balance
    });
  }, [locale, userData, isLoginFetching, balance]);

  return (
    <>
      <CatalogWrapper
        seoContent={{ slug: '', ...strapiContent }}
        dataStore={dataStore}
        games={games}
        license={license}
      ></CatalogWrapper>
    </>
  );
};

export default HomeCasino;

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const paths: { params: { segment: string }; locale: string }[] = [];

  // Don't need to add static paths /sports because it's already defined into the /pages directory
  getFullLicenses()
    .filter((license) => license.name !== 'F')
    .forEach(({ name }) => {
      (locales || ['fr', 'nl', 'en']).forEach((locale) => {
        paths.push({
          params: { segment: getSegmentByLicense(name) },
          locale
        });
      });
    });

  return {
    paths,
    fallback: false
  };
};

export const getStaticProps: GetStaticProps = async ({ locale, params }) => {
  const { segment } = params || {};
  if (!segment) throw Error('NO_SEGMENT_URL');

  const license = getLicenseBySegment(segment);
  const strapiContent: HomeContent = await getStrapiContent<StrapiHomeContent>({
    url: `${process.env.STRAPIAPI}/home-content?locale=${getFallbackLanguage(
      locale
    )}`,
    defaultContent: {
      name: '',
      metaTitle: '',
      metaDescription: '',
      metaKeywords: '',
      descriptionLead: '',
      descriptionHeader: '',
      descriptionContent: '',
      createdAt: '',
      updatedAt: ''
    }
  });

  const catalog = await getCatalog({
    locale,
    license: license === 'MAIN' ? undefined : license,
    minimal: true,
    isDataStoreEntityHasMinimalPayload: (entity?: string) =>
      entity !== 'highlightedTournaments'
  });

  const seoTagsConfig = getSeoTagsConfig({
    license,
    title: strapiContent?.metaTitle ?? strapiContent?.name,
    description: strapiContent?.metaDescription ?? undefined,
    keywords: strapiContent?.metaKeywords ?? undefined,
    createdAt: strapiContent?.createdAt,
    updatedAt: strapiContent?.updatedAt
  });

  return {
    ...catalog,
    props: {
      ...catalog.props,
      strapiContent,
      seoTagsConfig
    }
  };
};
